.loader-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  z-index: 1035;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2);
          box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2);
}
.loader-bg .loader-track {
  position: relative;
  height: 3px;
  display: block;
  width: 100%;
  overflow: hidden;
}
.loader-bg .loader-track .loader-fill:after, .loader-bg .loader-track .loader-fill:before {
  content: '';
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  will-change: left, right;
}
.loader-bg .loader-track .loader-fill:before {
  -webkit-animation: mbar 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
          animation: mbar 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
}
.loader-bg .loader-track .loader-fill:after {
  -webkit-animation: m_s 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
          animation: m_s 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
  -webkit-animation-delay: 1.15s;
          animation-delay: 1.15s;
}

@-webkit-keyframes mbar {
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -35%;
  }
}

@keyframes mbar {
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -35%;
  }
}
@-webkit-keyframes m_s {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}
@keyframes m_s {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}